import React from "react";

export default class Home extends React.Component {
  render() {
    return (
      <div>
        <div class="bg-black vh-100 w-100 d-flex justify-content-center align-items-center">
          <span class="text-white h2">Another empty page. Just check the discord tab </span>
        </div>
      </div>
    );
  }
}
