import "./App.scss";
import "bootstrap/scss/bootstrap.scss";
import React from "react";

import NavBar from "./component/NavBar";
import Footer from "./component/Footer";
import Home from "./page/Home";
import Discord from "./page/Discord";

import {
  BrowserRouter,
  Routes ,
  Route,
} from "react-router-dom";

export default function App() {
  return (
    <BrowserRouter>
      <Routes  >
        <Route path="/" element={<Discord/>}/>
        <Route path="/discord" element={<Discord/>}/>
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

